<template>
  <div class="tile">
    <div class="tile-content">
      <div class="tile-icon-container" v-html="icon" />
      <h4 class="tile-title" v-if="$slots.title">
        <slot name="title" />
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted () {
  }
}

</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

.tile {
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
}
.tile-clr-bg {
  padding-top: 64.5%;
}
.tile-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 16px;
  gap: 1rem;
}
.tile-title {
  color: #ffffff;
  font-family: TTCommons-Light;
  font-weight: bold;
  font-size: 44px;
  font-weight: 700;
  line-height: 1;
  margin: unset;
}
.tile-icon-container {
  display: flex;
  fill: #fff;
  height: 90px;
  width: 90px;
  justify-content: center;
}
@media (max-width: 75em) {
  .tile{
    height: 135px;
  }
  .tile-title {
    font-size: 35px;
  }
}
@media (max-width: 64em) {
  .tile{
    height: 135px;
  }
.tile-title {
    font-size: 30px;
  }
}
</style>
