<template>
  <modal name="modal-setdetails" :width="620">
    <div slot="header">
      {{ $t('Additional set equipment') }}
    </div>
    <div slot="uppertext">
      <span>Zvolené vybavení si můžete zapůjčit jako součást setu. Kromě daného vybavení můžete navolit i další, typicky potřebné součásti, které jsou pro provozování daného sportu vhodné a potřebné.</span><br><br>
      <span>U vybavení půjčovaného v setu tedy nemusíte vyhledávat doplňkové součásti sportovního vybavené samostatně, ale budou Vám automaticky nabídnuty jako doplňková výbava setu při uzavírání Vaší objednávky v košíku.</span>
      <br>
      <p> {{ $t('Current overview of additional set equipment: ') }}</p>
    </div>
    <div class="mb20 text-gray" slot="content">
      <span class="col1 bold pb10"> {{ $t('Borrowed sporting equipment') }}</span>
      <div v-if="product">
        <span class="col1">
          {{ product.name }}
        </span><br><br>
      </div>
      <span class="col1 bold pb10"> {{ $t('Additional Services') }}</span>
      <div v-if="product">
        <div v-for="service in product.additional_services" :key="service.id">
          <span class="col1">{{ service.name }} za {{ service.price }} Kč</span>
        </div>
      </div>
      <br>
      <span class="note mb20">Veškeré ceny jsou uvedeny v Kč vč. DPH</span>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from 'theme/components/core/ModalTwo'

export default {
  name: 'PriceList',
  computed: {
    ...mapGetters({
      product: 'product/getCurrentProduct'
    })
  },
  components: {
    Modal
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-setdetails')
    }
  }
}
</script>

<style scoped>
    .header {
      background: #fff;
    }
    .content.grey {
      background-color: rgb(255, 249, 249);
    }
    .text-gray {
      font-size: 18px;
    }
    .modal {
        font-size: 16px;
    }
    .col1 {
      font-family: 'TTCommons-Light';
    }
    .col1.bold {
      font-family: 'TTCommons-Medium';
      color: #1d1d1d;
      margin-bottom: 3px;
    }
</style>
